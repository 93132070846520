import endpoint from "../constants/endPoints";
import { ISaveMeal } from "../interfaces/MealInterface";
import ApiResponse from "../resources/entity/IApiResponse";
import * as http from "../utils/http";

/**
 * @description:- getMealHistory api for  getting dashboard circle functionality
 * @returns
 */

export const addCoustomRecipe = (data: {
  items: ISaveMeal[];
  name: string;
  linkedTo?: number | null;
}): Promise<ApiResponse> => http.post(endpoint.recipe.CUSTOM_RECIPE, data);

export const addNewRecipes = (data: {
  items: ISaveMeal[];
  name: string;
  linkedTo?: number | null;
}): Promise<ApiResponse> => http.post(endpoint.recipe.NEW_RECIPE, data);

export const getRecipeHistory = (page: number): Promise<ApiResponse> => {
  return http.get(
    endpoint.recipe.GET_RECIPE_HISTORY.replace(":page", `${page}`)
  );
};

export const deleteRecipe = (id: { meal: number }): Promise<ApiResponse> =>
  http.post(endpoint.recipe.DELETE_RECIPE_HISTORY, id);
