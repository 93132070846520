import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { IonRangeCustomEvent, RangeChangeEventDetail } from "@ionic/core";
import { IonLabel, IonRange, IonSpinner } from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

import CommonButton from "../../components/commonButton/CommonButton";
import { ISunExp } from "../../interfaces/SunExpInterface";
import { IAuthReducerState } from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/reducers/Index";

import { addNewSunExp } from "../../services/dashboard";
import helper from "../../utils/helper";
import toast from "../../utils/toastsMessage";
import { ION_DATE_TIME_FORMAT } from "../../constants/commonConstants";
import addSunExpValidationSchema from "../../validationSchemas/addSunExposoreValidation";
import { IManageEvents } from "../../interfaces/EventInterface";
import { IAddSun } from "../../interfaces/DashboardInterface";
import CommonDatePicker from "../../components/commonDatePicker";

import "./Dashboard.scss";

interface IProps {
  linkedTo?: number;
  updateEvent?: (args: IManageEvents) => void;
  data?: IManageEvents;
  fromDashboard?: boolean;
}

const AddSunExposure = (props: IProps) => {
  const { t: translation } = useTranslation();
  const [amountState, setAmtState] = useState<IAddSun>({
    amount: 30,
    iutotal: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStart] = useState<Date>();
  const [endTime, setEnd] = useState<Date>();

  const userData: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  /**
   *
   * Form handling using react-hook-form and validating fields using yupResolver
   */
  const {
    control,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addSunExpValidationSchema(translation)),
  });

  const handleChange = (e: IonRangeCustomEvent<RangeChangeEventDetail>) => {
    setAmtState({
      iutotal: helper.getIuTotal(startTime as Date, endTime as Date, userData),
      [e.target.name]: e.target.value,
    });
  };

  /**
   * The function onSubmit is an asynchronous function that handles the submission of a form, adds a new
   * sun exposure record, and displays success or error messages.
   */
  const onSubmit = async (data: ISunExp) => {
    setLoading(true);
    const response = await addNewSunExp({
      ...data,
      amount: amountState.amount as number,
      total_iu: amountState.iutotal as number,
      linkedTo: props.linkedTo ? props.linkedTo : null,
    });
    clearErrors(["start", "end"]);

    setLoading(false);
    if (response && response?.data && response?.data?.success === true) {
      if (props?.fromDashboard && props.updateEvent && props.data) {
        await props.updateEvent(props.data);
      }
      toast.success(translation("supexp_added_successfully"));
    }
    else {
      toast.error(translation(`${response?.data?.errors}`));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (watch("start" || "end")) {
      const data = {
        start: watch("start"),
        end: watch("end"),
      };

      setStart(data.start);
      setEnd(data.end);
    }
  }, [watch(["start", "end"])]);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data as ISunExp))}>
      <div>
        <CommonDatePicker
          control={control}
          presentation="time"
          label="Start Time"
          placeHolder="__:__ __"
          className="form-control"
          name="start"
          error={errors.start}
          defaultValue={moment().format(ION_DATE_TIME_FORMAT) as string}
        />
        <CommonDatePicker
          control={control}
          presentation="time"
          label="End Time"
          placeHolder="__:__ __"
          className="form-control"
          name="end"
          error={errors.end}
          defaultValue={moment().format(ION_DATE_TIME_FORMAT) as string}
        />
        <div className="form-group">
          <IonLabel>
            {" "}
            {translation("exposure_amount")}
            <div className="pills success">
              <p>{amountState?.amount ? amountState?.amount : 30}%</p>
            </div>
          </IonLabel>
          <div className="custom-select">
            <IonRange
              name="amount"
              value={amountState?.amount ? amountState.amount : 30}
              disabled={endTime && startTime ? false : true}
              pin
              onIonChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <CommonButton
              type="submit"
              parentClassName="primary-btn"
              name="Save"
            >
              {loading ? <IonSpinner name="dots" /> : null}
            </CommonButton>
            <CommonButton
              type="button"
              parentClassName="grey-btn"
              name="Reset"
              onClick={() => {
                reset({});
              }}
            />
          </div>

          <div className="pills success">
            <p>{amountState?.iutotal ? amountState?.iutotal : 0} Iu&apos;s </p>
          </div>
        </div>
        <div></div>
        <br />
        <br />

        <div className="form-group">
          <div className="theme-table">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>{translation("clothing")}</td>
                    <td>{translation("exposure")}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr onClick={() => setAmtState({ amount: 10 })}>
                    <td>{translation("long_sleeve_pants")}</td>
                    <td>{translation("10%")}</td>
                  </tr>
                  <tr onClick={() => setAmtState({ amount: 30 })}>
                    <td>{translation("short_sleeve_pants")}</td>
                    <td>{translation("30%")}</td>
                  </tr>
                  <tr onClick={() => setAmtState({ amount: 50 })}>
                    <td>{translation("short_sleeve_shorts")}</td>
                    <td>{translation("50%")}</td>
                  </tr>
                  <tr onClick={() => setAmtState({ amount: 70 })}>
                    <td>{translation("no_shirt_shorts")}</td>
                    <td>{translation("70%")}</td>
                  </tr>
                  <tr onClick={() => setAmtState({ amount: 80 })}>
                    <td>{translation("bikini")}</td>
                    <td>{translation("80%")}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <div className="text-center table-info">
                        <p>{translation("hint_press_row_for_quick_set")}.</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddSunExposure;
