import { closeOutline } from "ionicons/icons";
import { IonButton, IonHeader, IonIcon, IonModal } from "@ionic/react";

import RecipeComposition from "./RecipeComposition";
import { IFoodData } from "../../interfaces/MealInterface";

interface IProps {
  openMealDetail: boolean | undefined;
  setOpenMealDetail(arg0: boolean): void;
  searchFoodData: IFoodData | undefined;
}

const AddUserRecipeDetail = (props: IProps) => {
  return (
    <IonModal
      isOpen={props.openMealDetail}
      trigger="open-modal"
      onWillDismiss={() => props.setOpenMealDetail(false)}
      className="theme-modal"
    >
      <IonHeader>
        <div className="heading">
          <h2>{props.searchFoodData?.name}</h2>
        </div>
        <div className="modal-closer">
          <IonButton onClick={() => props.setOpenMealDetail(false)}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </IonHeader>

      <RecipeComposition data={props.searchFoodData} />
    </IonModal>
  );
};
export default AddUserRecipeDetail;
