// import { useTranslation } from "react-i18next";

// const { t: translation } = useTranslation();
// console.log(`${translation("hip_blank")}`,"PPPPPPPPPPP");

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;

export const EventType = [
  { id: "nil", value: "No Action" },
  { id: "activity", value: "Activity" },
  { id: "sunexp", value: "Sun Exposure" },
  { id: "weight", value: "Measure" },
  { id: "meal", value: "Meals" },
];
export const LIST_RECORDS_LIMIT = 10;

export const weekDays: Array<string> = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const GENDER = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
];
export const METRIC = [
  { id: 0, value: "Imperial & Conventional (in/ft/lb)" },
  // { id: 1, value: "Metric & SI (cm/km/kg)" },
];
export const IMAGE_FILE_SIZE_LIMIT = 20; //  Mb
export const DEFAULT_LAST_LAT = 42.33;
export const DEFAULT_LAST_LONG = -83;
export const PAGE_RANGE_DISPLAYED = 5;

export const IMAGE_FILE_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".jpg",
  ".JPEG",
  ".PNG",
];
export const MeasureType = [{ id: "g", value: "g" }];
export const INTENSITY = [
  { id: "resting", value: "Resting" },
  { id: "vlight", value: "Very Light" },
  { id: "light", value: "Light" },
  { id: "moderate", value: "Moderate" },
  { id: "heavy", value: "Heavy" },
];

export const DATE_FORMAT = "MM-DD-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const MONTH_YEAR_FORMAT = "MMM YYYY";
export const ION_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const Page = {
  NEW_EVENTS: "new_event",
  NEW_SUBJECTIVE: "new_subjective",
  SUBJECTIVE_HISTORY: "subjective_history",
  NEW_ACTIVITY: "new_activity",
  NEW_MEAL: "new_meal",
  MEAL_HISTORY: "meal_history",
  EVENT_LIST: "event_list",
  MANAGE_EVENTS: "manage_events",
  MY_INFO: "my_info",
  PROFILE_PROGRESS: "profile_progress",
  ACTIVITY_HISTORY: "activity_history",
  NEW_LAB: "new_lab",
  LAB_HISTORY: "lab_history",
  NEW_MEASURE: "new_measure",
  MEASUREMENTS_HISTORY: "measurements_history",
  NEW_RECIPE: "new_recipe",
  RECIPE_HISTORY: "recipe_history",
};

export const SUBJECTIVE_FIELDS = [
  {
    name: "mood",
    label: "Mood",
    ratings: [
      "Miserable",
      "Awful",
      "Bad",
      "Not the Best",
      "OK",
      "Alright",
      "Pretty Good",
      "Good",
      "Great",
      "Fantastic",
    ],
  },
  {
    name: "skin_quality",
    label: "Skin Quality ",
    ratings: [
      "Very Dry and Splotchy",
      "Dry and Poor Color",
      "Poor",
      "Could Be Better",
      "OK",
      "Not Bad",
      "Decent",
      "Nice",
      "Soft",
      "Very Soft and Even Color",
    ],
  },
  {
    name: "clothing_fit",
    label: "Clothing Fit",
    ratings: [
      "Busting Seams",
      "Very Bad Fit",
      "Bad Fit",
      "Poor Fit",
      "OK",
      "Decent Fit",
      "Nice Fit",
      "Good Fit",
      "Very Good Fit",
      "Perfect Fit",
    ],
  },
  {
    name: "hair_nail_quality",
    label: "Hair and Nail Quality",
    ratings: [
      "Very Brittle and Breaking",
      "Rough and Weak",
      "Poor",
      "Could Be Better",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Very Good",
      "Very Smooth and Strong",
    ],
  },
  {
    name: "recovery",
    label: "Recovery From Exercise",
    ratings: [
      "5.5 Days",
      "5 Days",
      "4.5 Days",
      "4 Days",
      "3.5 Days",
      "3 Days",
      "2.5 Days",
      "2 Days",
      "1.5 Days",
      "1 Day",
    ],
  },
  {
    name: "sleep_quality",
    label: "Sleep Quality ",
    ratings: [
      "Insomniac",
      "Constantly Waking",
      "Trouble Sleeping",
      "Hit or Miss",
      "OK",
      "Not Bad",
      "Decent Sleep",
      "Good Sleep",
      "Great Sleep",
      "Like the Dead",
    ],
  },
  {
    name: "energy_levels",
    label: "Energy Levels",
    ratings: [
      "Dead",
      "Exhausted",
      "Tired",
      "Somewhat Tired",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Great",
      "Boing!",
    ],
  },
  {
    name: "libido",
    label: "Libido",
    ratings: [
      "No Interest",
      "Rarely Interested",
      "Interested Monthly",
      "Interested Bi-Weekly",
      "Interested Weekly",
      "Interested Every Few Days",
      "Interested Daily",
      "Interested Several Times a Day",
      "Interested Hourly",
      "Always Interested",
    ],
  },
  {
    name: "hunger_cravings",
    label: "Hunger Cravings",
    ratings: [
      "Always Starving",
      "Hungry Hourly",
      "Craving Snacks Between Meals",
      "Occasional Snack Cravings",
      "OK Waiting for Meals",
      "Mostly Satiated",
      "No Snack Cravings",
      "No Meal Cravings",
      "Skipping Lunch",
      "What Food?",
    ],
  },
  {
    name: "body_heat",
    label: "Body Heat",
    ratings: [
      "Freezing",
      "Very Cold",
      "Cold",
      "Cool",
      "Normal",
      "Warm",
      "Very Warm",
      "Hot",
      "Very Hot",
      "Human Torch",
    ],
  },
  {
    name: "headaches",
    label: "Headaches",
    ratings: [
      "Constant",
      "Several Per Hour",
      "Hourly",
      "Daily",
      "Every Few Days",
      "Weekly",
      "Monthly",
      "Rarely",
      "Long Time Ago",
      "None",
    ],
  },
];

export const SUBJECTIVE_INITIAL = {
  mood: 5,
  skin_quality: 5,
  clothing_fit: 5,
  hair_nail_quality: 5,
  sleep_quality: 5,
  recovery: 5,
  energy_levels: 5,
  libido: 5,
  hunger_cravings: 5,
  body_heat: 5,
  headaches: 5,
  linkedTo: undefined,
};

export const GENDER_TYPE = {
  MALE: "male",
  FEMALE: "female",
};

export const TIME = {
  SUNUP: "sunup",
  SUNSET: "sunset",
  SUND: "sund",
  SUNDE: "sunde",
};

export const LAB_FIELDS = [
  {
    identifier: "hscrp",
    name: "HSCRP",
    metric: "nmol/L",
    standard: "mg/dL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "t3",
    name: "Free T3",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "t4",
    name: "Free T4",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "tsh",
    name: "TSH",
    metric: "uIU/mL",
    standard: "uIU/mL",
    conversion: null,
  },
  {
    identifier: "rt3",
    name: "Reverse T3",
    metric: "pmol/L",
    standard: "ng/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "amsc",
    name: "Salivary Cortisol (AM)",
    metric: "nmol/L",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },
  {
    identifier: "pmsc",
    name: "Salivary Cortisol (PM)",
    metric: "nmol/L",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },

  {
    identifier: "hdl",
    name: "HDL Level",
    metric: "mmol/L",
    standard: "mg/dL",
    conversion: "mgdlToMmol",
  },

  {
    identifier: "sf",
    name: "Serum Ferritin",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngmlToPmol",
  },

  {
    identifier: "hc",
    name: "Homocystine",
    metric: "Umol/L",
    standard: "mg/L",
    conversion: "ngdlToPmol",
  },

  {
    identifier: "ft",
    name: "Fasting Triglycerides",
    metric: "mmol/L",
    standard: "mg/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "fi",
    name: "Fasting Insulin",
    metric: "pmol/L",
    standard: "uIU/mL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "a1c",
    name: "Hemoglobin A1c",
    metric: "%",
    standard: "%",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "weight",
    name: "Weight",
    metric: "lbs",
    standard: "lbs",
    conversion: "ngdlToPmol",
  },
];
export const MESURMENT_FIELD = [
  {
    identifier: "waist",
    standard: "Waist at Narrowest (in) ",
    placeHolder: "0 in",
  },
  {
    identifier: "hip",
    standard: "Hip at widest (in) ",
    placeHolder: "0 in",
  },
  {
    identifier: "neck",
    standard: "Neck at Narrowest (in) ",
    placeHolder: "0 in",
  },
  {
    identifier: "weight",
    standard: "Weight (lbs) ",
    placeHolder: "0 lbs",
  },
];
export const Toggle: Array<string> = [
  "email_toggle",
  "sms_toggle",
  "web_notification_toggle",
];
