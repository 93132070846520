import { closeOutline } from "ionicons/icons";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import { IFoodData, IMealHistory } from "../../interfaces/MealInterface";

interface IProps {
  selectedYourMealsDeatail(item: IFoodData[]): void;
  openYourMeals: boolean | undefined;
  setOpenYourMeals(arg0: boolean): void;
  selectYourMealData: IMealHistory[];
}

const AddUserRecipe = (props: IProps) => {
  const { t: translation } = useTranslation();
  return (
    <IonModal
      isOpen={props.openYourMeals}
      trigger="open-modal"
      onWillDismiss={() => props.setOpenYourMeals(false)}
      className="theme-modal"
    >
      <IonHeader>
        <div className="heading">
          <h2>{translation("your_meals")}</h2>
        </div>
        <div className="modal-closer">
          <IonButton onClick={() => props.setOpenYourMeals(false)}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="modal-body">
          <div className="form-group">
            <IonLabel>{translation("meal_composition")}</IonLabel>
            <div className="theme-table">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <td>{translation("food")}</td>
                      <td>{translation("amount")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectYourMealData.map((item: any, index) => (
                      <tr
                        key={index}
                        onClick={() => props.selectedYourMealsDeatail(item)}
                      >
                        <td>{item[0].name}</td>
                        <td>{item[1]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default AddUserRecipe;
