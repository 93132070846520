import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";

import React from "react";

import { FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { FileValidationError } from "../../interfaces/CommonInterface";

interface IProps {
  error?: FieldErrors<FieldValues> | FieldError | FileValidationError;
}

const CommonErrorMessage: React.FC<IProps> = (props) => {
  const { error } = props;

  return error && error?.message ? (
    <div className="message error">
      <p>
        <IonIcon icon={alertCircleOutline} />
        {error?.message}
      </p>
    </div>
  ) : null;
};

export default CommonErrorMessage;
