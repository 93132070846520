import { useEffect, useState } from "react";
import { closeOutline } from "ionicons/icons";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import CommonButton from "../../components/commonButton/CommonButton";
import CommonSelect from "../../components/commonSelect/CommonSelect";
import CommonTextBox from "../../components/commonTextbox/CommonTextBox";

import { addMealValidationSchema } from "../../validationSchemas/mealsValidation";
import { IMeasureDropdown, IMeta } from "../../interfaces/MealInterface";
import ConfirmModal from "../../components/commonModal/ConfirmModal";
import {
  IRecipeFoodData,
  IRecipeMeta,
  IRestrictedDataItem,
} from "../../interfaces/RecipeInterface";

interface IProps {
  setOpenSearchMealDetail(arg: boolean): void;
  onSubmit(arg: IMeta): void;
  measureList: { id: string | number; value: string | number }[];
  openSearchMealDetail: boolean;
  selectMealData: IMeasureDropdown | any;
  restrictedError: { key: number | undefined; value: string }[];
  setRestrictedError: React.Dispatch<
    React.SetStateAction<{ key: number | undefined; value: string }[]>
  >;
}

const AddRecipe = (props: IProps) => {
  const { t: translation } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [recipedata, setRecipeData] = useState<IRecipeMeta>();
  const [measureData, setMeasureData] = useState<string>("");
  const [restrictedData, setRestrictedData] = useState<IRestrictedDataItem[]>(
    []
  );

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addMealValidationSchema(translation)),
  });

 
  useEffect(() => {
    clearVals();
  }, [props.openSearchMealDetail]);

 /**
  * The function `clearVals` clears the values and errors of a form after a 500ms delay.
  */
  const clearVals = async () => {
    setTimeout(() => {
      reset({});
      clearErrors();
    }, 500);
  };

  /**
   * The function `checkRestricted` checks if a recipe is restricted and performs certain actions based
   * on the result.
   */
  const checkRestricted = (data: IRecipeMeta) => {
    const info = props.selectMealData.food_weights.filter(
      (i: IRecipeFoodData) => i.id?.toString() === data.measure?.toString()
    );

    const isrestricted = props.selectMealData.is_restricted;
    if (isrestricted) {
      setMeasureData(info[0].weight_description);
      setValue("amount", data.amount);
      setRecipeData(data);
      setRestrictedData(info);
      setOpen(true);
    } else {
      props.onSubmit(data as IRecipeMeta);
    }
  };

  return (
    <>
      <IonModal
        isOpen={props.openSearchMealDetail}
        trigger="open-modal"
        onWillDismiss={() => props.setOpenSearchMealDetail(false)}
        className="theme-modal"
      >
        <IonHeader>
          <div className="heading">
            <h2>{translation("add_meal")}</h2>
          </div>
          <div className="modal-closer">
            <IonButton onClick={() => props.setOpenSearchMealDetail(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </div>
        </IonHeader>
        <IonContent className="ion-padding">
          <form
            onSubmit={handleSubmit((data) => {
              setOpen(false);
              checkRestricted(data as IRecipeMeta);
              clearErrors();
            })}
            noValidate
          >
            <div className="modal-body">
              <CommonTextBox
                label="Servings"
                fieldType="input"
                control={control}
                type="number"
                className="form-control"
                placeHolder="0"
                name="amount"
                error={errors.amount}
              />
              <CommonSelect
                label="Household Measure"
                className="common-select mb20"
                dropDownData={props.measureList}
                control={control}
                name="measure"
                multipleSelect={false}
                value={measureData}
                error={errors.measure}
              />
              <CommonButton
                type="submit"
                parentClassName="primary-btn"
                name="Add meals"
              ></CommonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>

      {/* confirmModal for restricted food */}
      <ConfirmModal
        isOpen={open}
        confirmText={`${translation(
          "are_you_sure_you_want_to_add_restricted_food"
        )} ?`}
        cancelText={translation("no")}
        submitText={translation("yes")}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={() => {
          setValue("amount", "");
          reset({ amount: "" });
          const newErrorMessage = props.selectMealData.validation_text[0];
          props.setRestrictedError([
            ...props.restrictedError,
            { key: restrictedData[0]?.ndb_no, value: newErrorMessage },
          ]);
          props.onSubmit(recipedata as IRecipeMeta);
        }}
      />
    </>
  );
};
export default AddRecipe;
