import React, { KeyboardEvent } from "react";
import { TextFieldTypes, InputChangeEventDetail } from "@ionic/core";
import { IonIcon, IonLabel } from "@ionic/react";
import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";

import CommonErrorMessage from "../commonError/CommonErrorMessage";
import CommonInput from "../commonInput/CommonInput";
import { FileValidationError } from "../../interfaces/CommonInterface";

interface IProps {
  label?: string;
  hasIcon?: boolean;
  type: TextFieldTypes;
  className: string;
  placeHolder?: string;
  name: string;
  value?: string | number | null;
  control?: Control;
  inputIcon?: string;
  error?: FieldErrors<FieldValues> | FieldError | FileValidationError;
  fieldType?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxlength?: number;
  ref?: HTMLInputElement;
  max?: string;
  onChange?: (event: CustomEvent<InputChangeEventDetail>) => void;
  onKeyDown?: (event: CustomEvent<KeyboardEvent>) => void;
  onIconClick?: () => void;
  onClick?: () => void;
  required?: boolean;
  bottomLabel?: string;
  icon?: number;
  buttonLabel?: string;
}

const CommonTextBox: React.FC<IProps> = (props) => {
  const {
    buttonLabel,
    onIconClick,
    label,
    type,
    className,
    placeHolder,
    name,
    control,
    error,
    value,
    max,
    disabled = false,
    readonly = false,
    onChange,
    onKeyDown,
    inputIcon = "",
    onClick,
    maxlength,
    required,
    bottomLabel,
    hasIcon,
  } = props;
  function disableScroll() {
    const activeElement = document.activeElement as HTMLInputElement | null;
    activeElement?.blur();
    document.removeEventListener("wheel", disableScroll);
  }
  return (
    <Controller
      render={({ field }) => (
        <div className="form-group">
          {label ? (
            <IonLabel className="font14 ">
              {label}
              {required ? <sup className="colorRed">*</sup> : null}
              {hasIcon ? (
                <div className="pills success ml-10">
                  <p>{buttonLabel ?? 0}</p>
                </div>
              ) : null}
            </IonLabel>
          ) : null}
          <div
            className="form-relative right-icon-input"
            onClick={() => {
              !disabled && onClick && onClick();
              type === "number" &&
                document.addEventListener("wheel", disableScroll);
            }}
          >
            <CommonInput
              {...field}
              type={type}
              disabled={disabled}
              readonly={readonly}
              className={className}
              placeholder={placeHolder}
              value={field.value}
              maxlength={maxlength}
              max={max}
              onBlur={field.onBlur}
              onKeyDown={onKeyDown}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
            />
            {inputIcon ? (
              <IonIcon
                icon={inputIcon}
                className="colorBlack"
                onClick={() => !onClick && onIconClick && onIconClick()}
              />
            ) : null}
          </div>

          {bottomLabel && <span className="label-bottom">{bottomLabel}</span>}

          {/* Common error message  */}
          <CommonErrorMessage error={error} />
        </div>
      )}
      defaultValue={value}
      name={name}
      control={control}
    />
  );
};

export default React.memo(CommonTextBox);
