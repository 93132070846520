import { IonButton, IonSpinner } from "@ionic/react";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNodeArray,
  ReactPortal,
} from "react";
import { ReactI18NextChild } from "react-i18next";

interface IProps {
  children?:
    | string
    | number
    | boolean
    | Record<string, unknown>
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | ReactNodeArray
    | ReactPortal
    | Iterable<ReactI18NextChild>
    | null
    | undefined;
  spinnerClassName?: string | undefined;
  loading?: boolean;
  fill?: "clear" | "outline" | "solid" | "default" | undefined;
  expand?: "full" | "block" | undefined;
  parentClassName: string;
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  type?: "submit" | "button";
  name?: string;
  disabled?: boolean;
}

const CommonButton = (prop: IProps) => {
  return (
    <IonButton
      onClick={(e) => {
        if (prop.onClick) {
          prop.onClick(e);
        }
      }}
      type={prop.type}
      disabled={prop.disabled}
      className={`theme-button ${prop.parentClassName}`}
      expand={prop.expand}
      fill={prop.fill}
    >
      {prop.loading ? (
        <IonSpinner name="dots" className={prop.spinnerClassName} />
      ) : null}
      {prop.name}
      {prop.children}
    </IonButton>
  );
};

export default CommonButton;
