import {
  IonContent,
  IonModal,
} from "@ionic/react";


type Props = {
  isOpen: boolean;
  onClose?: () => void;
};
const CommonVideoModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <IonModal
      isOpen={isOpen}
      trigger="open-modal"
      onWillDismiss={onClose}
      className="theme-modal video-modal"
    >
      <IonContent className="ion-padding">
        <div className="modal-body">
          <iframe
            width="100%"
            height="460"
            src="https://www.youtube.com/embed/goa4O8Be250?autoplay=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default CommonVideoModal;
